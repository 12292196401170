import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/TripSegment.module.css';
import { Label } from 'semantic-ui-react';
import { DestinationName } from '@utils/constants';

function TripSegment({ trip = {}, handleOnView, handleOnCopy, handleOnDelete }) {
    const gotoTrip = (tripID) => {
        if (!!handleOnView) {
            handleOnView(tripID);
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.leading_container}>
                <div className={styles.title}>
                    {trip.userDefined} {!!trip.public && <Label>模版</Label>}
                </div>
                <div className={styles.actions_container}>
                    {!!handleOnView && (
                        <button className="primary_btn slim_btn" onClick={() => gotoTrip(trip.id)} title="查看">
                            查看
                        </button>
                    )}

                    {!!handleOnCopy && (
                        <>
                            <div className="gap" />
                            <button className="primary_btn slim_btn" onClick={() => handleOnCopy(trip.id)} title="复制">
                                复制
                            </button>
                        </>
                    )}

                    {!!handleOnDelete && (
                        <>
                            <div className="gap" />
                            <button
                                className="primary_btn slim_btn"
                                onClick={() => handleOnDelete(trip.id)}
                                title="删除"
                            >
                                删除
                            </button>
                        </>
                    )}
                </div>
            </div>
            {trip.total > 0 && <div className={styles.total_container}>参考价格: $A{trip.total}</div>}
            {!!trip.notes && <div className={styles.notes_container}>{trip.notes}</div>}

            {trip?.destinations?.length > 0 ? (
                <div className={styles.destinations_container}>
                    {trip.destinations.map((elem, index) => {
                        return (
                            <div className={styles.destination_container} key={index}>
                                <div className={styles.destination_name}>{DestinationName(elem.name)}</div>
                                <div>{elem?.plays?.length > 0 && `(${elem.plays.length} 个活动)`}</div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div>请点击“查看”，添加目的地和体验</div>
            )}
        </div>
    );
}

TripSegment.propTypes = {
    trip: PropTypes.shape({}).isRequired,
    handleOnView: PropTypes.func,
    handleOnDelete: PropTypes.func,
    handleOnCopy: PropTypes.func,
};

export default TripSegment;

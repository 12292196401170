import React from 'react';
import styles from '@styles/TripsHomePage.module.css';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function HomePage() {
    return (
        <div className={styles.container}>
            <div className={styles.title}>行程规划</div>
            <div className={styles.list_container}>
                <Link to={`my-trips`}>
                    <div className={styles.item_container}>
                        <div className={styles.leading_container}>
                            <div className={styles.title}>创建自己的旅程</div>
                            <div className={styles.steps_container}>
                                <div className={styles.step}>
                                    <div className={styles.step_title}>
                                        使用我们的行程规划工具来组织您旅程的每个部分，创建或者编辑自己的行程。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.trailing_container}>
                            <Icon className={styles.icon} size="big" name="angle right" />
                        </div>
                    </div>
                </Link>
                <Link to={`templates`}>
                    <div className={styles.item_container}>
                        <div className={styles.leading_container}>
                            <div className={styles.title}>使用策划的旅程规划</div>
                            <div className={styles.steps_container}>
                                <div className={styles.step}>
                                    <div className={styles.step_title}>
                                        <div className={styles.number_container}>
                                            <div className={styles.number}>1</div>
                                        </div>
                                        选择策划好的旅程规划
                                    </div>
                                    <p>每一次旅行都是由专家针对不同目的地和主题精心策划的。</p>
                                </div>
                                <div className={styles.step}>
                                    <div className={styles.step_title}>
                                        <div className={styles.number_container}>
                                            <div className={styles.number}>2</div>
                                        </div>
                                        让它成为你自己的
                                    </div>
                                    <p>混合搭配旅程项目或复制整个行程。让规划行程变得快速而简单。 </p>
                                </div>
                                <div className={styles.step}>
                                    <div className={styles.step_title}>
                                        <div className={styles.number_container}>
                                            <div className={styles.number}>3</div>
                                        </div>{' '}
                                        预订并完成
                                    </div>
                                    <p>在一个地方预订所有景点。</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.trailing_container}>
                            <Icon className={styles.icon} size="big" name="angle right" />
                        </div>
                    </div>
                </Link>
            </div>
            <div></div>
        </div>
    );
}

export default HomePage;

import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as config from '@config';
import { useState, useEffect } from 'react';
import { GetToken } from '../utils/storage';

// total is used to trigger re-render
function CommissionAmount({ orderItemID = '', playID = '', dstID = '', tripID = '', total = 0 }) {
    const [fetching, setFetching] = useState(false);
    const [amount, setAmount] = useState();

    const controller = new AbortController();

    useEffect(() => {
        if (!!orderItemID || !!playID || !!dstID || !!tripID) {
            fetchCommission();
        }
        return () => {
            if (fetching) controller.abort();
        };
    }, [orderItemID, playID, dstID, tripID, total]);

    const fetchCommission = () => {
        setFetching(true);
        const url = new URL(`${config.apiURL}/commission/amount`);
        url.searchParams.set('orderItemID', orderItemID);
        url.searchParams.set('playID', playID);
        url.searchParams.set('destinationID', dstID);
        url.searchParams.set('tripID', tripID);
        axios
            .get(url, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const amount = res.data.amount;
                setAmount(amount);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setFetching(false);
            });
    };

    return <>{!!fetching ? '加载中...' : !!amount ? `A$${amount}` : '-'}</>;
}

CommissionAmount.propTypes = {
    orderItemID: PropTypes.string,
    playID: PropTypes.string,
    dstID: PropTypes.string, //destinationID
    tripID: PropTypes.string,
    total: PropTypes.number, //used to trigger re-render
};

export default CommissionAmount;

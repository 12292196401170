import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/Collapsible.module.css';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

/**
 * Collapsible includes trigger
 *
 * @param trigger - Element to trigger
 * @param className - Collapsible style
 * @param basic - hide left badge
 * @param children - Content
 * @param active - collapsed
 */
function Collapsible({
    trigger,
    trailingBadge,
    className,
    initCollapsed = true,
    active = null,
    basic = false,
    animate = true,
    callAfterCollapsed = () => {},
    children,
}) {
    const [collapsed, setCollapsed] = useState(true);
    const [collapsibleClassList, setCollapsibleClassList] = useState(styles.collapsible);
    const ref = useRef();
    const observerRef = useRef(null);

    useLayoutEffect(() => {
        if (!initCollapsed) {
            setCollapsed(false);
        }
    }, []);

    useLayoutEffect(() => {
        const txEndHandler = (e) => {
            const maxHeight = ref.current.style.maxHeight;
            if (!!maxHeight == false) {
                callAfterCollapsed();
            }
        };
        ref.current.addEventListener('transitionend', txEndHandler);
        return () => {
            ref.current.removeEventListener('transitionend', txEndHandler);
        };
    }, []);

    useLayoutEffect(() => {
        if (collapsed) {
            close();
        } else {
            open();
        }
    }, [collapsed]);

    // controlled from outside
    useLayoutEffect(() => {
        if (active != null) {
            setCollapsed(active);
        }
    }, [active]);

    const open = () => {
        setCollapsibleClassList(`${styles.collapsible} ${styles.expand} ${basic && styles.basic}`);
        const content = ref.current;
        if (animate) {
            content.style.maxHeight = content.scrollHeight + 'px'; //has animation
        } else {
            content.style.maxHeight = '10000vh'; // no animation
        }
    };

    const close = () => {
        setCollapsibleClassList(`${styles.collapsible} ${basic && styles.basic}`);
        const content = ref.current;
        content.style.maxHeight = null;
    };

    const handleOnClick = () => {
        setCollapsed((prev) => !prev);
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper} onClick={handleOnClick}>
                <div className={`${collapsibleClassList} ${className}`}>
                    {trigger}
                    {trailingBadge != null && (
                        <div className={styles.trailing_badge} style={collapsed ? { rotate: '180deg' } : null}>
                            {trailingBadge}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.content} ref={ref}>
                {children}
            </div>
        </div>
    );
}

Collapsible.propTypes = {
    trigger: PropTypes.element,
    initCollapsed: PropTypes.bool,
    active: PropTypes.bool,
    trailingBadge: PropTypes.element,
    callAfterCollapsed: PropTypes.func,
    basic: PropTypes.bool,
    animate: PropTypes.bool,
};

export default Collapsible;

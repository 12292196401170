import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Icon, Loader, Modal, Button, Form, TextArea } from 'semantic-ui-react';
import Collapsible from './Collapsible';
import TripDestinationPlays from './TripDestinationPlays';
import { DestinationName } from '@utils/constants';
import styles from '@styles/TripDestination.module.css';
import axios from 'axios';
import * as config from '@config';
import { GetToken } from '../utils/storage';

function TripDestination({
    index,
    destination = {},
    inSortMode = false,
    callback = () => {},
    draggable = false,
    handleOnDragStart = () => {},
    handleOnDragOver = () => {},
    handleOnDrop = () => {},
    compact,
}) {
    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        if (open == false) {
            setNotes(destination.notes);
        }
        return () => {
            if (submitting) controller.abort();
        };
    }, [destination, open]);

    const handleOnDelete = (dstID, name) => {
        if (confirm(`确定删除"${name}"以及其选择的项目?`) != true) return;
        setSubmitting(true);
        const url = new URL(`${config.apiURL}/destination/${dstID}`);
        axios
            .delete(url, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const reply = res.data.reply;
                // console.log(reply);
                callback();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleOnChange = (e, { name, value }) => {
        setNotes(value);
    };

    const handleSubmit = () => {
        var req = { notes: notes };
        setSubmitting(true);
        axios
            .patch(`${config.apiURL}/destination/${destination.id}`, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                callback();
                setOpen(false);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div
            className={styles.container}
            draggable={draggable}
            aria-selected={inSortMode && 'true'}
            onDragStart={(e) => handleOnDragStart(e, index)}
            onDragOver={(e) => handleOnDragOver(e)}
            onDrop={(e) => handleOnDrop(e, index)}
        >
            <div className={styles.leading_container}>
                <div className={styles.title}>
                    {DestinationName(destination.name)}{' '}
                    {destination?.plays.length > 0 && `(${destination?.plays.length}个活动)`}
                    <button className="primary_btn slim_btn" onClick={() => setOpen(true)}>
                        备注
                    </button>
                </div>
                {!!inSortMode ? (
                    <Icon size="large" name="list" />
                ) : (
                    <Icon
                        className={styles.icon}
                        size="large"
                        color="red"
                        name="trash alternate"
                        onClick={() => handleOnDelete(destination.id, DestinationName(destination.name))}
                    />
                )}
            </div>
            <div className={styles.notes_container}>{destination.notes}</div>
            {!inSortMode && (
                <>
                    <Collapsible basic initCollapsed={false} active={inSortMode} trigger={<></>} animate={false}>
                        <TripDestinationPlays destination={destination} callback={callback} compact={compact} />
                        <div className={styles.trailing_container}></div>
                    </Collapsible>
                </>
            )}

            <Modal open={open} onClose={() => setOpen(false)} closeOnDimmerClick={false} closeIcon>
                <Modal.Header>备注</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <TextArea
                                rows={5}
                                name="notes"
                                placeholder="Notes"
                                value={notes || ''}
                                onChange={handleOnChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)}>取消</Button>
                    <Button onClick={handleSubmit} primary>
                        更新
                    </Button>
                </Modal.Actions>
            </Modal>

            <Dimmer active={submitting} page inverted>
                <Loader></Loader>
            </Dimmer>
        </div>
    );
}

TripDestination.propTypes = {
    index: PropTypes.number.isRequired,
    destination: PropTypes.object.isRequired,
    inSortMode: PropTypes.bool,
    callback: PropTypes.func.isRequired,
    draggable: PropTypes.bool,
    handleOnDragStart: PropTypes.func.isRequired,
    handleOnDragOver: PropTypes.func.isRequired,
    handleOnDrop: PropTypes.func.isRequired,
    compact: PropTypes.bool,
};

export default TripDestination;

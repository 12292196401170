import React, { useEffect, useState } from 'react';
import styles from '@styles/TripsPage.module.css';
import axios from 'axios';
import * as config from '@config';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router';
import TripSegment from '@components/TripSegment';
import BackBar from '@components/BackBar';
import { GetToken } from '../../utils/storage';

function TripTemplatesPage(props) {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]); //trips
    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const controller = new AbortController();

    useEffect(() => {
        fetchData();
        return () => {};
    }, []);

    const fetchData = () => {
        setFetching(true);
        const url = new URL(`${config.apiURL}/trips`);
        url.searchParams.set('public', true);
        axios
            .get(url, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const trips = res.data.trips;
                if (trips) {
                    setData(trips);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setFetching(false);
            });
    };

    const gotoTrip = (id) => {
        navigate(id);
    };

    const handleOnCopy = (tripID) => {
        const url = new URL(`${config.apiURL}/trip/${tripID}/copy`);
        setSubmitting(true);
        axios
            .post(
                url,
                {},
                {
                    signal: controller.signal,
                    headers: { Authorization: `Bearer ${GetToken()}` },
                }
            )
            .then((res) => {
                // goto copied trip
                const reply = res.data.reply;
                if (reply) {
                    alert('已复制到“我的行程”');
                }
                const tripID = res.data.tripID;
                if (tripID) {
                    navigate(`../my-trips/${tripID}`, { replace: true });
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <BackBar />

            <div className={styles.container}>
                <div className={styles.top_container}>
                    <div className={styles.title}>行程模板</div>
                </div>
                <div className={styles.grid_container}>
                    {data.map((item, index) => {
                        return (
                            <TripSegment key={index} trip={item} handleOnCopy={!!item.public ? handleOnCopy : null} />
                        );
                    })}
                </div>
                <Dimmer active={fetching || submitting} page inverted>
                    <Loader></Loader>
                </Dimmer>
            </div>
        </>
    );
}

export default TripTemplatesPage;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/ActivityPicker.module.css';
import { Button, Icon, Modal } from 'semantic-ui-react';
import * as config from '@config';

function ActivityPicker({ activities = [], plays = [], onSelect = () => {} }) {
    return (
        <div className={styles.container}>
            {activities.map((item, index) => {
                const selected = !!plays.find((elem) => elem.activityID == item.id);
                return <ActivityPickerItem key={index} activity={item} selected={selected} onSelect={onSelect} />;
            })}
        </div>
    );
}

ActivityPicker.propTypes = {
    activities: PropTypes.array.isRequired,
    plays: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
};

function ActivityPickerItem({ activity = [], selected = [], onSelect = () => {} }) {
    const [open, setOpen] = useState(false);
    return (
        <div className={styles.activity_container}>
            <div className={styles.img_container}>
                <img
                    src={`${config.baseURL}/public/${activity.photos?.[0]?.filename}`}
                    onError={(e) => (e.currentTarget.src = `${config.baseURL}/public/empty_image.png`)}
                    loading="lazy"
                />
            </div>
            <div className={styles.title_container}>
                {activity.name}
                {selected && `(已选)`}
                {selected && <Icon name="check circle" color="green" />}
            </div>
            <div className={styles.actions_container}>
                <Button onClick={() => setOpen(true)} compact basic>
                    查看
                </Button>
                <Button className={styles.select_btn} onClick={() => onSelect(activity.id)} compact basic>
                    选择
                </Button>
            </div>
            <Modal onClose={() => setOpen(false)} open={open} size="fullscreen" closeIcon>
                <Modal.Header>{activity.name}</Modal.Header>
                <Modal.Content scrolling>
                    <div className={styles.preview_container}>
                        <div className={styles.images_container}>
                            {activity?.photos?.map((elem, index) => {
                                return (
                                    <div key={index} className={styles.img_container}>
                                        <img
                                            src={`${config.baseURL}/public/${elem.filename}`}
                                            onError={(e) =>
                                                (e.currentTarget.src = `${config.baseURL}/public/empty_image.png`)
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.content_container}>
                            <h3 className={styles.activity_title}>{activity.name}</h3>
                            <div
                                className={`${styles.description_container} workaround_description`}
                                dangerouslySetInnerHTML={{
                                    __html: activity.content,
                                }}
                            />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="返回" onClick={() => setOpen(false)} />
                </Modal.Actions>
            </Modal>
        </div>
    );
}

ActivityPickerItem.ActivityPickerItem = {
    activity: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
};

export default ActivityPicker;

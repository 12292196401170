import React from 'react';
import { Route, Routes } from 'react-router';
import MyTripsPage from './MyTripsPage';
import TripPage from './TripPage';
import TripTemplatesPage from './TripTemplatesPage';
import HomePage from './HomePage';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

function Index() {
    const fetching = useSelector((state) => state.activities.fetching);
    return (
        <>
            <Routes>
                <Route path="" exact element={<HomePage />} />
                <Route path="/my-trips" exact element={<MyTripsPage />} />
                <Route path="/my-trips/:tripID" exact element={<TripPage />} />
                <Route path="/templates" exact element={<TripTemplatesPage />} />
                <Route path="/:tripID" exact element={<TripPage />} />
            </Routes>
            <Dimmer active={fetching} page inverted>
                <Loader></Loader>
            </Dimmer>
        </>
    );
}

export default Index;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from '@styles/TripDestinationPlaysRow.module.css';
import ActivityPricing from '@components/activity/ActivityPricing';
import QtyController from './QtyController';
import axios from 'axios';
import * as config from '@config';
import { Link } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';
import CommissionAmount from './CommissionAmount';
import { GetToken } from '../utils/storage';

function TripDestinationPlaysRow({ play = {}, callback = () => {}, compact }) {
    const activities = useSelector((state) => state.activities.data);
    const [activity, setActivity] = useState({});
    const [req, setReq] = useState();
    const [submitting, setSubmitting] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        filterActivity();
        return () => {};
    }, [activities, play.activityID]);

    useEffect(() => {
        if (!!req) {
            handleBookingChange(req);
        }
    }, [req]);

    useEffect(() => {
        return () => {
            if (submitting) controller.abort();
        };
    }, []);

    const filterActivity = () => {
        const activity = activities.find((item) => item.id == play.activityID);
        if (activity) {
            setActivity(activity);
        }
    };

    const handleOnChangeSegment = (e) => {
        const segmentID = e.target.value;
        // console.log('segmentID', segmentID);
        if (!!segmentID) {
            setReq((prev) => {
                return { ...prev, segmentID };
            });
        }
    };

    const handleOnChangeVariant = (e) => {
        const variantID = e.target.value;
        // console.log('variantID', variantID);
        if (!!variantID) {
            setReq((prev) => {
                return { ...prev, variantID };
            });
        }
    };

    const handleBookingChange = (data) => {
        const req = {
            activityID: play.activityID,
            destinationID: play.destinationID,
            ...data,
        };
        // console.log(req);
        setSubmitting(true);
        axios
            .patch(`${config.apiURL}/play/${play.id}`, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                callback();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleDelete = () => {
        if (confirm(`删除 "${activity.name}"?`) != true) return;
        setSubmitting(true);
        axios
            .delete(`${config.apiURL}/play/${play.id}`, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                callback();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className={styles.container}>
            <div className={styles.cell_container}>
                {compact != true && (
                    <img
                        src={`${config.baseURL}/public/${activity?.photos?.[0]?.filename}`}
                        onError={(e) => (e.currentTarget.src = `${config.baseURL}/public/empty_image.png`)}
                        loading="lazy"
                    />
                )}
                <div className={styles.activity_container}>
                    <div className={styles.title}>
                        {activity.name}
                        {activity.tourCode && (
                            <>
                                &nbsp;<Label>{activity.tourCode}</Label>
                            </>
                        )}
                        &nbsp;
                        <Link to={`/dashboard/activities/${activity.id}`}>
                            <Icon
                                style={{ cursor: 'pointer', color: 'LinkText' }}
                                name="external alternate"
                                title={activity.name}
                            />
                        </Link>
                    </div>
                    {activity.segmented && (
                        <div className="geg">
                            <label>时段：</label>
                            <select value={play?.bookingInfo?.segment?.id} onChange={handleOnChangeSegment}>
                                {!play?.bookingInfo?.segment?.id && <option value={''}>请选择</option>}
                                {activity?.segmentOptions?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                    {activity?.variants?.length > 0 && (
                        <div className="geg">
                            <label>套餐:</label>
                            <select
                                value={play?.bookingInfo?.variant?.id}
                                onChange={handleOnChangeVariant}
                                style={{ maxWidth: '150px' }}
                            >
                                {!play?.bookingInfo?.variant?.id && <option value={''}>请选择</option>}
                                {activity?.variants?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.nameZH}
                                        </option>
                                    );
                                })}
                            </select>
                            <div>
                                套餐说明：
                                {activity?.variants?.map((item, index) => {
                                    if (item.id == play?.bookingInfo?.variant?.id) {
                                        return <span key={index}>{item.description}</span>;
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.cell_container}>
                <ActivityPricing pricing={play.effectPricing} showFamily={play?.effectPricing?.hasFamilyPlan} />
            </div>

            <div className={styles.cell_container}>
                <QtyController
                    bookingInfo={play.bookingInfo}
                    bundlePriced={play.effectPricing?.bundlePriced}
                    hasFamilyPlan={play.effectPricing?.hasFamilyPlan}
                    setReq={setReq}
                />
            </div>

            <div className={styles.cell_container}>价格: A${play.total}</div>

            {/* <div className={styles.cell_container}>
                佣金: <CommissionAmount playID={play.id} total={play.total} />
            </div> */}

            <div className={styles.cell_container}>
                <Icon name="trash alternate" className={styles.button} onClick={handleDelete} />
            </div>
        </div>
    );
}

TripDestinationPlaysRow.propTypes = {
    play: PropTypes.shape({
        id: PropTypes.string.isRequired,
        activityID: PropTypes.string.isRequired,
        destinationID: PropTypes.string.isRequired,
        effectPricing: PropTypes.object,
        bookingInfo: PropTypes.object,
        total: PropTypes.number,
    }).isRequired,
    callback: PropTypes.func.isRequired,
    compact: PropTypes.bool,
};

export default TripDestinationPlaysRow;

import React, { useEffect, useState } from 'react';
import styles from '@styles/TripsPage.module.css';
import axios from 'axios';
import * as config from '@config';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router';
import TripSegment from '@components/TripSegment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrips } from '@features/trips/tripsSlice';
import BackBar from '@components/BackBar';
import { GetToken } from '../../utils/storage';

function MyTripsPage(props) {
    const trips = useSelector((state) => state.trips.data);
    const fetching = useSelector((state) => state.trips.fetching);
    const [submitting, setSubmitting] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const controller = new AbortController();

    useEffect(() => {
        return () => {
            if (submitting) controller.abort();
        };
    }, []);

    const handleOnCreate = () => {
        const req = {};
        const url = new URL(`${config.apiURL}/trips`);
        setSubmitting(true);
        axios
            .post(url, req, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                // console.log(res.data);
                dispatch(fetchTrips());
                const id = res.data.id;
                if (id) {
                    navigate(id);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleDelete = (id) => {
        if (confirm('确定删除整个行程？') != true) return;
        setSubmitting(true);
        const url = new URL(`${config.apiURL}/trip/${id}`);
        axios
            .delete(url, {
                signal: controller.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            })
            .then((res) => {
                const reply = res.data.reply;
                if (reply) {
                    alert(reply);
                }
                dispatch(fetchTrips());
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                console.error(msg);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const gotoTrip = (id, hash) => {
        navigate(`${id}`);
    };

    return (
        <>
            <BackBar />
            <div className={styles.container}>
                <div className={styles.top_container}>
                    <div className={styles.title}>
                        我的行程 <Loader inline active={fetching}></Loader>
                    </div>
                    <button className="primary_btn" onClick={handleOnCreate}>
                        创建新行程
                    </button>
                </div>
                <div className={styles.grid_container}>
                    {trips?.map((item, index) => {
                        return (
                            <TripSegment
                                key={index}
                                trip={item}
                                handleOnView={gotoTrip}
                                handleOnDelete={handleDelete}
                            />
                        );
                    })}
                </div>
                <Dimmer active={submitting} page inverted>
                    <Loader></Loader>
                </Dimmer>
            </div>
        </>
    );
}

export default MyTripsPage;
